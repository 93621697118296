<template>
  <section id="dashboard-ecommerce">

    <b-row class="match-height">
      <b-col xl="12" md="12">
        <b-card class="card-congratulation-medal">
          <b-card-text class="font-small-3 text-danger">
            <strong style="text-transform: bold">
              <feather-icon icon="BellIcon" font-scale="3" animation="cylon" />
              Cher <b class="text-primary">{{ userName }}</b>, vous n'etes pas en ordre, il vous reste {{ dashboard_data.dette_restante_achat_packet
              }}$ à payer pour bénéficier des avantages de {{ userRole }}
            </strong>
          </b-card-text>
          <b-button to="/transaction/recharge/recharge-add-new" variant="primary"></strong>
            Créditer le compte pour {{ userRole }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="4" md="6">
        <b-card v-if="data.congratulations" class="card-congratulation-medal">
          <h5>Félicitation 🎉 {{ data.congratulations.name }}!</h5>
          <b-card-text class="font-small-3">
            Solde du portefeuille
          </b-card-text>
          <h3 class="mb-75 mt-2 pt-50">
            <b-link style="font-size: 40px">{{ dashboard_data.wallet_balance }}$</b-link>
          </h3>
          <b-button :to="{ path: '/bonus' }" class="btn btn-primary" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary">Voir les détails
          </b-button>

          <b-img :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal" alt="Medal Pic" />
        </b-card>

      </b-col>
      <b-col xl="8" md="6">
        <b-card no-body class="card-statistics">
          <b-card-header>
            <b-card-title>Statistiques</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Mise à jour chaque semaine
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              
              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-danger">
                      <feather-icon size="24" icon="UsersIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Parrainés
                    </h4>
                    <!-- "wallet_balance": 0,
                    "balance": 0,
                    "somme_gain": 0,
                    "somme_bonus": 0,
                    "total_retrait": 0,
                    "total_recharge": 45 -->
                    <b-card-text class="font-small-3 mb-0">
                      {{ dashboard_data.total_invite }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Dépots
                    </h4>
                    <!-- "wallet_balance": 0,
                    "balance": 0,
                    "somme_gain": 0,
                    "somme_bonus": 0,
                    "total_retrait": 0,
                    "total_recharge": 45 -->
                    <b-card-text class="font-small-3 mb-0">
                      {{ dashboard_data.total_recharge_pack_actuel }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Gains
                    </h4>
                    <!-- "wallet_balance": 0,
                    "balance": 0,
                    "somme_gain": 0,
                    "somme_bonus": 0,
                    "total_retrait": 0,
                    "total_recharge": 45 -->
                    <b-card-text class="font-small-3 mb-0">
                      {{ dashboard_data.somme_gain }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Bonus
                    </h4>
                    <!-- "wallet_balance": 0,
                    "balance": 0,
                    "somme_gain": 0,
                    "somme_bonus": 0,
                    "total_retrait": 0,
                    "total_recharge": 45 -->
                    <b-card-text class="font-small-3 mb-0">
                      {{ dashboard_data.somme_bonus }}$
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
         
         <!--  <b-col lg="4" md="6" cols="4">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          
          <b-col lg="4" md="6" cols="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="4" md="6" cols="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col> -->

          <!--  <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col> -->
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <!--  <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <b-col xl="12" md="12">
        <b-card class="card-congratulation-medal">
          <b-card-text class="font-small-3">
            <a :href="sharedLink" target="__blank"> {{ sharedLink }}</a>
          </b-card-text>
          <b-card-text class="font-small-3">
            <!-- AddToAny BEGIN -->
            <div class="a2a_kit a2a_kit_size_32 a2a_default_style" :data-a2a-url="sharedLink">
            <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
            <a class="a2a_button_copy_link"></a>
            <a class="a2a_button_whatsapp"></a>
            <a class="a2a_button_facebook"></a>
            <a class="a2a_button_twitter"></a>
            <a class="a2a_button_telegram"></a>
            <a class="a2a_button_facebook_messenger"></a>
            <a class="a2a_button_google_gmail"></a>
            <a class="a2a_button_viber"></a>
            <a class="a2a_button_sms"></a>
            </div>
            <script type="application/javascript">
            var a2a_config = a2a_config || {};
            a2a_config.locale = "fr";
            </script>
            <script type="application/javascript" async src="https://static.addtoany.com/menu/page.js"></script>
            <!-- AddToAny END -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Company Table Card -->
      <!--  <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col> -->
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col> -->
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <!-- <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col> -->
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <!--  <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col> -->
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <!--  <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col> -->
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BImg,
  BButton,

  BCardHeader,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardBody,

} from 'bootstrap-vue'
import { kFormatter } from "@core/utils/filter";

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    
    BCardBody,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,

    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  data() {
    return {
      data: {},
      dashboard_data: {},
      userRole: "...",
      sharedLink: "",
      userName: "...",
    }
  },
  directives: {
    Ripple,
  },
  mounted() {

    // load data
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.sharedLink = userData.sharedLink;
    this.userRole = userData.role;
    this.userName = userData.fullName; 

    // get data for dashboard
    myApi({
      url: "dashboard-client",
      method: "get",
    })
      .then((response) => {

        this.dashboard_data = response.data.data;

      })
      .catch((error) => {

      });

  },
  created() {
    // data
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
